
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

.grey-box{
  background: $base-grey;
  padding:15px;
  @include media(lg){
    padding:20px 30px;
  }
  @include media(xl){
    padding:50px 95px;
  }
}

.news-box{
  .items-container{
    @include flex-block;
    @include flex-wrap(wrap);
    margin:0 -15px;
    position: relative;
    @include media(xl){
      margin:0 -30px;
    }
    &:before{
      content:"";
      width:2px;
      height:100%;
      position: absolute;
      left:0;
      top:0;
      background: $base-grey;
    }
  }
}

.news-container{
  @include flex-block;
  @include flex-wrap(wrap);
  margin:0 -15px;
  position: relative;
  @include media(xl){
    margin:0 -30px;
  }
  &:before{
    content:"";
    width:2px;
    height:100%;
    position: absolute;
    left:0;
    top:0;
    background: $base-grey;
  }
}

.news-item{
  width:100%;
  min-width:100%;
  padding:0 15px 15px;
  margin:0 0 15px 0;
  border-left:1px dotted $color-line;
  @include media(md){
    width:50%;
    min-width: 50%;
  }
  @include media(xl){
    padding:0 30px;
      margin:0 0 30px 0;
    width:33.33%;
    min-width: 33.33%;
  }
  &-name{
    @include font-size(20px);
    display: inline-block;
    @include media(lg){
      @include font-size(24px);
    }
  }
  &-date{
    @include font-size(14px);
    font-weight: bold;
    padding:10px 0 0 0;
    @include media(lg){
      @include font-size(16px);
    }
  }
  &-perex{
    @include font-size(14px);
    padding:20px 0 0 0;
    @include media(lg){
      @include font-size(16px);
    }
  }
  &.hp-respo-news{
    @include media(xl){
      display:none;
    }
  }
}

.map-container{
  @include flex-block;
  padding:50px 0 0 0;
  @include flex-direction(column);
  @include media(lg){
    @include flex-direction(row);
  }
}

.map-menu{
  @include media(lg){
    width:345px;
    min-width: 345px;
  }
  ul{
    margin:0;
    padding:0;
    list-style: none;
    position: relative;
    z-index: 666;
    li{
      padding:0;
      margin:0;
      border-bottom: 1px solid $second-color;
      position: relative;
      cursor: pointer;
      &:before{
        display:none;
      }
      span,
      a{
        font-weight: bold;
        @include flex-block;
        @include align-items(center);
        @include justify-content(space-between);
        background: #e5e5e5;
        padding:20px;
        cursor: pointer;
        color:$second-color;
        @include font-size(14px);
        &:focus,
        &:hover{
          background: #fff;
          color:$main-color;
          text-decoration: none;
          span{
            color:$main-color;
          }
        }
        span{
          padding:0;
          background: none;
          font-weight: normal;
        }
        &:after{
          content:"";
          width:10px;
          height:10px;
          @include border-radius(50%);
          display:block;
        }
      }
      ul{
        border-top: 1px solid $second-color;
        @include transition;
        z-index: 8888;
        @include media(lg){
          position: absolute;
          border:none;
          width:345px;
          right:-345px;
          visibility: hidden;
          @include opacity(0);
          top:15px;
        }
        li{
          @include media(lg){
            border-color: #e5e5e5;
          }
          span,
          a{
            background: #d2d0d0;
            padding:20px 20px 20px 40px;
            @include justify-content(flex-start);
            cursor: poniter;
            @include media(lg){
              background: #fff;
              padding:20px;
            }
            &:after{
              display:none;
            }
            span{
              padding:0 0 0 5px;
              background: none;
              font-weight: normal;
            }
          }
          &:last-child{
            border:none;
            @include media(lg){
              border-bottom: 1px solid #e5e5e5;
            }
          }
        }
      }
      &:hover{
        ul{
          @include media(lg){
            visibility: visible;
            @include opacity(1);
            right:-345px;
            top:0;
            @include box-shadow(2px 2px 5px rgba(0,0,0,0.4));
          }
        }
      }
    }
  }
}

@for $i from 1 through 10 {
  .color-#{$i} {
    color:map-get($house-colors, "color-"+$i);
    &:after{
      background: map-get($house-colors, "color-"+$i);
    }
   }
}

.promo-container{
  width:100%;
  @include flex-block;
  @include flex-wrap(wrap);
  @include media(xl){
    min-height: 650px;
  }
  .promo-image{
    width:100%;
    min-height: 200px;
    position: relative;
    @include background-size(cover);
    background-repeat: no-repeat !important;
    background-position: center right !important;
    @include media(md){
      width:50%;
    }
    a{
      position: absolute;
      font-weight: bold;
      color:$second-color;
      text-decoration: none !important;
      font-size: 16px;
      white-space: nowrap;
      top:100%;
      right:0;
      margin-top:-10px;
      @include flex-block;
      @include align-items(center);
      &:before{
        content:"\f35d";
        width:60px;
        text-decoration: none !important;
        text-align: center;
        line-height:60px;
        height:60px;
        min-width: 60px;
        font-size: 25px;
        background: #0b3f8c;
        display:block;
        color:#fff;
        margin:0 20px 0 0;
        @include border-radius(50%);
        @include media(md){
          width:80px;
          height:80px;
          min-width: 80px;
          line-height:80px;
          font-size: 30px;
        }
      }
      @include media(md){
        top:auto;
        right:auto;
        left:100%;
        margin-left:-40px;
        bottom:50px;
        margin-top:0;
      }
      &:focus,
      &:hover{
        color:$main-color;
      }
    }
  }
  .promo-text{
    width:100%;
    padding: 60px 20px 40px 20px;
    @include media(md){
      width:50%;
      padding: 50px 0px 160px 40px;
    }
    @include media(lg){
      padding: 80px 40px 160px 40px;
    }
    @include media(xl){
      padding: 100px 80px 160px 80px;
    }
    h3{
      color:$main-color;
      margin:0 0 15px 0;
    }
  }
}

.news-detail-date{
  font-size: 12px;
  padding:0 0 15px 0;
}

.map-box{
  width:100%;
}

#map-canvas{
  height:500px;
  width:100%;
}

.marker-detail-container{
  display:none;
}

.marker-detail{
  &-name{
    padding: 10px 30px 10px 10px;
    font-size: 14px;
    a{
      color:$base-black;
      font-weight: bold;
      &:focus,
      &:hover{
        text-decoration: none;
        color:$main-color;
      }
    }
  }
  &-url{
     a{
       color:$base-black;
       padding:10px 10px 0 10px;
       color:#989dab;
       text-transform: uppercase;
       display:block;
       font-weight: bold;
       &:after{
         content:"\f061";
         float: right;
         margin: 2px 5px;
       }
       &:focus,
       &:hover{
         text-decoration: none;
         color:$main-color;
       }
     }
  }
  &-image{
    img{
      width:220px;
    }
  }
}

.flex-perex{
  @include flex-block;
  @include flex-wrap(wrap);
  @include media(sm){
    @include flex-wrap(nowrap);
  }
  .perex-image{
    width:100%;
    min-width: 100%;
    padding:0 0 20px 0;
    @include media(sm){
      width:250px;
      min-width: 250px;
      padding:0 20px 0 0;
    }
  }
}


.marker-detail {
    font-size: 14px;
    overflow: hidden;
    padding: 0 ;

    &-position {
        //margin-top: 30px;
        margin-left: 25px;
        width: 215px !important;
    }

    &-flyout {
        width: 215px !important;

        > div {
          //height:auto !important;
            &:nth-child(1) {
                display:none !important;
            }

            &:nth-child(2) {
                border-radius: 5px !important;
                width: 215px !important;
                //display:none;
            }

            &:nth-child(3) {
                //display:none;

                 & > div {
                    margin-left: -25px;
                }

                &:before {
                    content: '';
                    display: none;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 15px 15px 0 15px;
                    border-color: #fff transparent transparent transparent;
                }
            }

            &:nth-child(4) {
                width: 215px !important;
                background-color: #fff !important;
                border-radius: 5px !important;
                border: 0 !important;
            }
        }

    }

    &-close {
        opacity: 1 !important;
        width: 30px !important;
        height: 30px !important;
        text-align: center;
        top: 4px !important;
        right: 0 !important;
        font-weight: bold;

        &:before {
            content: '×';
            color: $second-color;
            font-size: 22px;
            line-height: 25px;
        }

        img {
            display: none;
        }
    }

    .gm-style-iw & {
        color: #fff;
        font-family: "Roboto", sans-serif;
    }
}

.gm-style-iw{
  top:0 !important;
  left:0 !important;
  width: 230px !important;
  background: #fff;
  border-radius: 10px;
}
