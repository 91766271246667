
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";

.pux-container{
    margin-left: auto;
    margin-right: auto;
    padding-left: $container-padding-left;
    padding-right: $container-padding-right;
    max-width: $container-max-width;
    &:after{
        display: block;
        content: "";
        clear: both;
    }
    @include media(md){
        padding-left: $container-padding-left-md;
        padding-right: $container-padding-right-md;
    }
}

.pux-container{
    .row{
        @include flex-block;
        @include flex-wrap(wrap);
        margin-left: $row-margin-left;
        margin-right: $row-margin-right;
        @include media(md){
          margin-left: $row-margin-left-md;
          margin-right: $row-margin-right-md;
        }
    }
}

.pux-container{
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
    .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12,
    .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
    .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
    .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
    .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
    .col-xl-auto{
        padding-left: $col-padding-left;
        padding-right: $col-padding-right;
        @include media(md){
          padding-left: $col-padding-left-md;
          padding-right: $col-padding-right-md;
        }
    }
}

.pux-fluid-container{
    padding-left: $fluid-container-padding-left;
    padding-righ: $fluid-container-padding-right;
    @include media(md){
      padding-left: $fluid-container-padding-left-md;
      padding-righ: $fluid-container-padding-right-md;
    }
    @include media(xl){
      padding-left: $fluid-container-padding-left-xl;
      padding-righ: $fluid-container-padding-right-xl;
    }
}

header{
  padding:20px 0;
  .header{
    @include flex-block;
    @include align-items(center);
    @include justify-content(space-between);
    &-logo{
      a{
        @include flex-block;
        @include align-items(center);
        color:#989dab;
        font-weight: bold;
        font-size: 14px;
        @include media(xl){
          font-size: 16px;
        }
        div{
          display:none;
          @include media(lg){
            display:block;
          }
        }
        span{
          color: $second-color;
        }
        img{
          width:45px;
          min-width: 45px;
          margin:0 15px 0 0;
        }
        &:focus,
        &:hover{
          text-decoration: none;
        }
      }
    }
    &-right{
      @include flex-block;
      @include align-items(center);
      @include media(md){
        position: relative;
      }
    }
    .muni-link{
      position: absolute;
      top:0px;
      left:125px;
      @include media(md){
        top:-25px;
        left:-80px;
      }
      a{
        font-size: 0;
        height:109px !important;
        overflow: hidden;
        display:block;
        img{
          width:60px;
          margin-top:-48px;
        }
      }
    }
    &-menu{
      z-index: 99;
      display:none;
      position: absolute;
      background: #fff;
      width:100%;
      left:0;
      top:102px;
      border-top:1px solid $color-line;
      border-bottom:1px solid $color-line;
      @include media(md){
        position: static;
        width:auto;
        border:none;
        display:block !important;
      }
      ul{
        margin:0;
        padding:0;
        list-style: none;
        @include flex-block;
        @include align-items(center);
        @include flex-wrap(wrap);
        @include media(sm){
          @include flex-wrap(nowrap);
        }
        li{
          padding:0 5px;
          width:100%;
          @include media(md){
            width:auto;
          }
          a{
            display:block;
            padding:15px 5px;
            font-weight: bold;
            color:$second-color;
            font-size: 14px;
            text-transform: uppercase;
            text-align: center;
            &:focus,
            &:hover{
              text-decoration: none;
              color:$main-color;
            }
            @include media(md){
              text-align: left;
              padding:10px 5px;
            }
          }
        }
      }
    }
    &-language{
      position: relative;
      margin:0 0 0 10px;
      z-index: 888;
      &:after{
        content:"";
        width:100%;
        bottom:-10px;
        position: absolute;
        height:12px;
      }
      &-current{
        width: 50px;
        height:50px;
        min-width: 50px;
        background: $second-color;
        @include border-radius(50%);
        color:#fff;
        font-size: 12px;
        text-align: center;
        line-height:50px;
        display:block;
        font-weight: bold;
        cursor: pointer;
      }
      &-inner{
        display:none;
        position: absolute;
        background: #fff;
        padding:15px 5px 5px 5px;
        width:90px;
        left:50%;
        margin-left:-45px;
        @include box-shadow(0px 0px 4px rgba(0,0,0,0.25));
        @include border-radius(10px);
        top:60px;
        ul{
          margin:0;
          padding:0;
          list-style: none;
          li{
            display:block;
            a{
              color:$second-color;
              font-weight: bold;
              font-size: 12px;
              display:block;
              text-align: center;
              margin:0 0 10px 0;
              &:focus,
              &:hover{
                text-decoration: none;
                color:$main-color;
              }
              &.active{
                display:none;
              }
            }
          }
        }
      }
      &.open{
        .header-language-inner{
          display:block;
        }
      }
    }
    &-open-menu{
      display:block;
      padding:0 0 0 15px;
      @include media(md){
        display:none;
      }
      .open-menu{
        width:40px;
        height:40px;
        display:block;
        position: relative;
        span{
          width:30px;
          left:5px;
          height:2px;
          background: $second-color;
          top:50%;
          position: absolute;
          margin-top:-1px;
          display:block;
          &:before,
          &:after{
            content:"";
            width:30px;
            position: absolute;
            height:2px;
            display:block;
            background: $second-color;
            left:0;
            @include transition;
          }
          &:before{
            top:-7px;
          }
          &:after{
            bottom:-7px;
          }
        }
        &.open{
          span{
            background: none;
            &:before{
              @include rotate(45deg);
              top:0;
            }
            &:after{
              @include rotate(-45deg);
              top:0;
            }
          }
        }
      }
    }
  }
}

.EditMode,
.DesignMode {
  header {
    position: static;
  }
}

footer{
  .footer{
    @include flex-block;
    @include align-items(center);
    @include flex-wrap(wrap);
    @include flex-direction(column);
    @include justify-content(center);
    padding:40px 0 20px 0;
    margin:0 -10px;
    @include media(xl){
      @include flex-direction(row);
      @include justify-content(flex-start);
    }
    &-item{
      @include flex-grow(1);
      padding:0 10px 20px 10px;
    }
  }
  .copyright{
    background: $base-grey;
    padding:15px 15px;
    @include font-size(12px);
    @include media(xl){
      padding:15px 90px;
    }
  }
}

.opvk-logo{
  img{
    max-width: 300px;
    width:100%;
  }
}

.car-logo{
  @include flex-block;
  @include align-items(center);
  color:#989dab;
  font-weight: bold;
  font-size: 11px;
  @include media(xs){
    font-size: 12px;
  }
  @include media(sm){
    font-size: 14px;
  }
  @include media(xl){
    font-size: 16px;
  }
  div{

  }
  span{
    color: $second-color;
  }
  img{
    width:45px;
    min-width: 45px;
    margin:0 15px 0 0;
  }
}

.muni{
  padding:0 10px;
  img{
    width:60px;
  }
}

.logos{
  @include flex-block;
  @include align-items(center);
  @include justify-content(center);
  margin:0 -20px;
}

.text-right{
  text-align: right;
}

.teiresias{
  padding:0 10px;
  @include media(xs){
    @include flex-block;
    @include align-items(center);
  }
  img{
    width:135px;
  }
  &-text{
    font-size: 10px;
    padding:10px 0 0 0;
    margin:10px 0 0 0;
    border-top:2px solid $second-color;
    @include media(xs){
      border-top:none;
      border-left:2px solid $second-color;
      padding:0 0 0 7px;
      margin:0 0 0 7px;
    }
  }
}

.overlay{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 777;
  display: none;
  overflow: hidden;
  width: 100%;
  background-color: rgba(0,0,0,.3);
  &.open{
    display: block;
  }
}

.is-xxl{
    display:none;
    @include media(xxl){
        display:block;
    }
}
