
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";


.cms-bootstrap [class^="icon-"]:before, .cms-bootstrap [class*=" icon-"]:before {
    font-family: 'Core-icons' !important;
}

*:before,
*:after{
    font-family: 'Font Awesome 5 Free' !important;
    -moz-osx-font-smoothing:grayscale;
    -webkit-font-smoothing:antialiased;
    display:inline-block;
    font-style:normal;
    font-variant:normal;
    text-rendering:auto;
    line-height:1
}

form,
html,
body{
    @include font-size(16px);
    font-family:$font-family;
    height:100%;
    color: $base-black;
    font-weight: 400;
    @include media(lg){
      @include font-size(18px);
    }
}

.marker{
  color:$main-color;
}

img{
    max-width:100%;
    height:auto !important;
}

@each $value in $px-values {
  .pt-#{$value} {
    @if($value > 20){
      @include pux-scale("padding-top", $value, 20);
    } @else {
      padding-top: #{$value}px;
    }
  }

  .pb-#{$value} {
    @if($value > 20){
      @include pux-scale("padding-bottom", $value, 20);
    } @else {
      padding-bottom: #{$value}px;
    }
  }

  .mt-#{$value} {
    @if($value > 20){
      @include pux-scale("margin-top", $value, 20);
    } @else {
      margin-top: #{$value}px;
    }
  }

  .mb-#{$value} {
    @if($value > 20){
      @include pux-scale("margin-bottom", $value, 20);
    } @else {
      margin-bottom: #{$value}px;
    }
  }
}

h1,
.h1{
    @include pux-scale-font(36px, 30px);
    margin: 20px 0 20px 0;
    font-weight: bold;
}

.text-page{
  h1{
    color:#181944;
    @include pux-scale-font(40px, 30px);
    margin: 20px 0 40px 0;
    font-weight: bold;
    border-bottom:1px solid #181944;
    padding:0 0 15px 0;
  }
  h2{
    margin:20px 0 30px 0;
  }
  h3,
  .h3{
      @include pux-scale-font(16px, 15px);
      margin:0 0 30px 0;
  }
}

h2,
.h2{
    @include pux-scale-font(30px, 24px);
    font-weight: bold;
    &.title-ico{
      border-bottom:1px solid $second-color;
      @include flex-block;
      @include align-items(center);
      @include justify-content(space-between);
      padding: 0 0 12px 0;
      margin:20px 0 35px 0;
      &:after{
        content:"\f129";
        @include font-size(30px);
        font-weight: bold;
      }
      &.ico-info{
        &:after{
          content:"\f129";
        }
      }
      &.ico-news{
        &:after{
          content:"\f0e7";
        }
      }
    }
}

h3,
.h3{
    @include pux-scale-font(24px, 20px);
    font-weight: bold;
}

h4,
.h4{
    @include pux-scale-font(18px, 16px);
    font-weight: bold;
}

big,
.perex{
    @include pux-scale-font($font-size-perex, $min-font-size-perex, $line-height-perex);
    font-weight: 300;
}

small,
.text-small{
  font-size: 16px;
  line-height: 26px;
  .col-lg-6{
    &:first-of-type{
      position: relative;
      @include media(lg){
        &:after{
          content:"";
          width:1px;
          position: absolute;
          height:100%;
          border-right:1px dotted #aba3ba;
          right:0;
          top:0;
        }
      }
    }
  }
}

.show-more-container{
  text-align: center;
}

a{
    color:$color-link;
    cursor: pointer;
    &:focus,
    &:hover{
        outline:none;
        color:darken($color-link, 10);
        text-decoration: none;
    }
}

.arrow-down-link,
.btn{
  display: inline-block;
  @include border-radius(0);
  border: none;
  padding:10px 15px;
  @include box-shadow(none);
  &:focus,
  &:hover{
      text-decoration: none;
      @include box-shadow(none);
  }
}

.arrow-down-link,
.btn-primary{
  background: none;
  color: $btn-primary-background;
  border:2px solid $btn-primary-background;
  &:focus,
  &:hover{
      text-decoration: none;
      background: $btn-primary-background;
      color: $btn-primary-color;
      border:2px solid $btn-primary-background;
  }
}

.btn-default{
  background:none;
  color: $btn-default-background;
  border:2px solid $btn-default-background;
  &:focus,
  &:hover{
      text-decoration: none;
      background: $btn-default-background;
      color: $btn-default-color;
      border:2px solid $btn-default-background;
  }
}

.image-top-center{
  position: top center !important;
}

.image-top-left{
  position: top left !important;
}

.image-top-right{
  position: top left !important;
}

.image-bottom-center{
  position: bottom center !important;
}

.image-bottom-left{
  position: bottom left !important;
}

.image-bottom-right{
  position: bottom right !important;
}

main{
    @include media(xl){
      @include calc("min-height", "100vh - 305px");
    }
    ol{
        margin:0;
        padding:0;
        list-style:none;
        counter-reset: my-ol;
        li{
            position:relative;
            margin:0 0 20px 0;
            padding:0 0 0 20px;
            font-size: 16px;
            &:before{
                content: counter(my-ol)".";
                counter-increment: my-ol;
                color:$ol-number;
                position:absolute;
                left:0;
                top:7px;
                font-weight:bold;
                font-size: 14px;
                font-family:$font-family !important;
            }
        }
    }
    ul{
        margin:0;
        padding:0;
        list-style:none;
        li{
            font-size: 16px;
            margin:0 0 20px 0;
            padding:0 0 0 25px;
            position:relative;
            &:before{
                content:"";
                width:6px;
                height:6px;
                background:$ul-bullet;
                position:absolute;
                top:11px;
                left:0;
                @include border-radius(50%);
            }
        }
    }
    table{
      width:100%;
      tr{
        border-bottom: 1px solid $table-tr-border;
        td{
          padding:10px 15px;
        }
        &:nth-child(even){
          background: $table-even-background;
        }
      }
    }
    .respo-table{
      table{
        width:100%;
        thead{
          display: none;
          @include media(md){
            display: table-header-group;
          }
          tr{
            border-bottom: 1px solid $table-tr-border;
            th{
              font-weight: bold;
              padding:10px 15px;
            }
          }
        }
        tbody{
          tr{
            display: block;
            border: 1px solid $table-tr-border;
            border-bottom: none;
            margin:0 0 20px 0;
            &:nth-child(even){
              background: $table-even-background;
            }
            @include media(md){
              display: table-row;
              margin: 0;
              border: none;
              border-bottom: 1px solid $table-tr-border;
            }
            td{
              @include flex-block;
              @include justify-content(space-between);
              width:100%;
              border-bottom: 1px solid $table-tr-border;
              @include media(md){
                display: table-cell;
                border: none;
                width:auto;
              }
              &:before{
                  content:attr(data-th);
                  display:block;
                  text-align:left;
                  font-family:$font-family !important;
                  margin:0 15px 0 0;
                  @include media(md){
                      display:none;
                  }
              }
            }
          }
        }
      }
    }
}

.controls-panel{
  padding:0;
  @include flex-block;
  @include align-items(center);
  @include justify-content(space-between);
  &-left{
    @include flex-block;
    @include align-items(center);
  }
  &-right{
    @include flex-block;
    @include align-items(center);
  }
  &-logo{
    width:60px;
    text-align: center;
    a{
      font-size: 0;
      width:30px;
      height:30px;
      display:inline-block;
      background:url(logo.svg) no-repeat center center;
      background-size: 30px 30px;
    }
  }
  &-name{
    font-size:16px;
    color:#dcdcf0;
    font-weight: bold;
    border-right:1px solid #434357;
    border-left:1px solid #434357;
    padding:19px 30px;
  }
  &-page{
    font-size:16px;
    color:#dcdcf0;
    padding:0 30px;
  }
  &-pager{
    padding:25px 30px;
    border-right:1px solid #434357;
    border-left:1px solid #434357;
  }
  &-thumb{
    padding:0 30px;
  }
  .thumnbnail-link{
    width:30px;
    height:30px;
    display:inline-block;
    background:url(gallery-icon.svg) no-repeat center center;
    background-size: 30px 30px;
    span{
      display:none;
    }
    &.open{
      background:url(gallery-icon-hover.svg) no-repeat center center;
    }
  }
}

.nav-btn{
  position: fixed;
  z-index: 99;
  top:0;
  height:100%;
  width:25%;
  a{
    position: absolute;
    @include opacity(0);
    top:50%;
    margin-top:-30px;
    width:40px;
    height:60px;
    margin-top:-30px;
    background: rgba(50,50,70,0.1);
    display:block;
    border-radius: 0;
    border:none;
    @include transition;
    &:after,
    &:before{
      background: none;
      border-right: 2px solid #fff;
      content: '';
      display: block;
      height: 8px;
      margin-top: -6px;
      position: absolute;
      @include rotate(135deg);
      top: 50%;
      width: 0;
    }
    &:after{
      margin-top: -1px;
      @include rotate(45deg);
    }
    &:hover{
      background: rgba(50,50,70,0.3);
      width:50px;
    }
    &.disabled{
      display:none;
    }
  }
  &.in-prev{
    left:0;
    a{
      left:0;
      &:after,
      &:before{
        right: 17px;
      }
      &:after{
        @include rotate(135deg);
      }
      &:before{
        @include rotate(45deg);
      }
    }
  }
  &.in-next{
    right:0;
    a{
      right:0;
      &:after,
      &:before{
        left: 17px;
      }
    }
  }
  &:hover{
    a{
      @include opacity(1);
    }
  }
}
